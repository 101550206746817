import React from "react";
import profileImage from "../../assets/images/man_1.png";

function Photo() {
  return (
    <div className="absolute flex justify-center w-full">
      <div className="relative -my-16 md:-my-24">
        <img
          src={profileImage}
          alt="profile"
          className="w-32 h-32 md:w-40 md:h-40 rounded-full mx-auto"
        ></img>
      </div>
    </div>
  );
}

export default Photo;
