import React from "react";
import styled from "styled-components";

function Cursor({ cursor }) {
  return (
    <CircleCursor>
      <CursorInner
        style={{
          transform: "translate(" + cursor.x + "px, " + cursor.y + "px)",
        }}
      />
      <CursorOuter
        style={{
          transform: "translate(" + cursor.x + "px, " + cursor.y + "px)",
        }}
      />
    </CircleCursor>
  );
}

const CursorOuter = styled.div`
  width: 30px;
  height: 30px;
  margin: -18px 0 0 -15px;
  border: 1px solid #304fff;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 10000000;
  opacity: 0.5;
  transition: all 0.08s ease-out;
  visibility: visible;
`;

const CursorInner = styled.div`
  width: 6px;
  height: 6px;
  margin: -3px 0 0 -3px;
  background-color: #00093d;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 10000001;
  opacity: 1;
  transition: all 0.08s ease-out;
  visibility: visible;
`;
const CircleCursor = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  visibility: hidden;
  outline: none;
`;

export default Cursor;
