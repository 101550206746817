import React from "react";

function Introduction() {
  const contactRef = React.useRef(null);

  const handleClick = () => {
    contactRef.current = document.getElementById("contact");
    contactRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const getAge = (birthDate) =>
    Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e10);

  const data = [
    {
      tag: "Age",
      value: getAge("1999-12-22") + " Years",
    },
    {
      tag: "Job",
      value: "Software Engineer",
    },
    {
      tag: "Citizenship",
      value: "India",
    },
    {
      tag: "Phone",
      value: "+91-9315687181",
    },
    {
      tag: "Email",
      value: "rishabhmishra3321@gmail.com",
    },
  ];

  const style = {
    dashedBorder: {
      borderBottom: "1px dashed rgba(0, 0, 0, 0.2)",
    },
  };

  return (
    <div className="w-full flex justify-center lg:px-0">
      <div className="w-full lg:max-w-6xl py-6 px-6 mx-4 lg:mx-0 lg:px-10 bg-white flex flex-wrap shadow-2xl rounded-md">
        <div className="w-full lg:w-2/5 pr-5">
          <div className="flex flex-wrap">
            {data.map((item, index) => {
              return (
                <div
                  className={`w-full flex flex-wrap pr-2 py-3 ${
                    index === 0 && "pt-0"
                  } font-normaltext-title-black`}
                  key={index}
                  style={index !== data.length - 1 ? style.dashedBorder : null}
                >
                  <div className="w-1/3 lg:w-1/4">
                    <p className="bg-primary inline text-white text-sm py-1 px-2 rounded-sm">
                      {item.tag + " :"}
                    </p>
                  </div>
                  <div className="pl-3 w-2/3 lg:w-3/4">
                    <p className="text-sm">{item.value}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="w-full mt-6 lg:mt-0 lg:w-3/5 bg-white">
          <p className="font-medium text-title-black text-lg">
            Hello! I'm Rishabh Mishra
          </p>
          <p className="text-base leading-7 my-3 text-subtitle-grey">
            From crafting pixel-perfect interfaces to building the pipelines
            that bring them to life, I'm passionate about the entire software
            development journey. This portfolio is an invitation to explore my
            work, and I'm always eager to connect and collaborate with fellow
            developers!
          </p>
          <p className="text-base leading-7 my-3 text-subtitle-grey">
            Sounds Impressive{" "}
            <span role="img" aria-label="smile">
              😀
            </span>
            , Let's connect{" "}
            <span role="img" aria-label="waving hand">
              👋
            </span>
          </p>
          <div className="flex justify-start my-2">
            <a href="https://bit.ly/Rishabh_Mishra" target="__blank">
              <button className="bg-primary text-sm text-white px-8 py-3 rounded-sm mr-5">
                Download CV
              </button>
            </a>
            <button
              className="bg-secondary text-sm text-title-black px-8 py-3 rounded-sm"
              onClick={handleClick}
            >
              Get in touch
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Introduction;
