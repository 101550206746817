import React from "react";
import styled from "styled-components";
import Photo from "./photo";

function Profile() {
  const socialData = [
    {
      title: "twitter",
      url: "https://twitter.com/0xSkadoosh",
    },
    {
      title: "github",
      url: "https://github.com/Rishabh3321",
    },
    {
      title: "linkedin",
      url: "https://www.linkedin.com/in/rishabhmishra3321/",
    },
  ];

  return (
    <div>
      <Photo />
      <div className="bg-secondary pt-20">
        <Title className="font-sans font-thin text-3xl md:text-4xl lg:text-5xl mx-auto w-full text-center py-2 md:py-4 text-title-black">
          Rishabh Mishra
        </Title>
        <div className="font-sans font-normal text-base text-center text-title-black">
          A Passionate Software Engineer
        </div>

        <div className="flex justify-center items-center gap-4 py-6">
          {socialData.map((data) => {
            return (
              <div
                className="rounded-full w-10 h-10 flex items-center justify-center bg-primary"
                key={data.title}
              >
                <a
                  className={`fa fa-${data.title} text-white  text-xl`}
                  href={data.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {""}
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Profile;

const Title = styled.div`
  line-height: 44px;
`;
