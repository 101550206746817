import React from "react";

function ErrorBlock({ error }) {
  return (
    <div
      className={`text-sm text-red-500 h-6 mt-1 transition-opacity duration-700 ease-in-out opacity-0 ${
        error !== "" && "opacity-100"
      } `}
    >
      {error}
    </div>
  );
}

export default ErrorBlock;
