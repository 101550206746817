import React from "react";
import background from "./assets/images/slide-bg_1.jpg";
import Contact from "./components/Contact";
import Experience from "./components/Experience";
import Introduction from "./components/Introduction";
import Profile from "./components/Profile";
import Skills from "./components/Skills";
import Cursor from "./components/Cursor/cursor";

function App() {
  const [cursor, setCursor] = React.useState({
    x: 0,
    y: 0,
  });
  return (
    <div
      className="relative"
      onMouseMove={(e) => {
        setCursor({ x: e.clientX, y: e.clientY });
      }}
    >
      <div
        className="stciky top-0 left-0 w-full h-80"
        style={{ backgroundColor: "#304fff" }}
      >
        <div
          className="w-full h-full bg-no-repeat bg-cover opacity-50 bg-center"
          style={{
            backgroundImage: `url(${background})`,
          }}
        ></div>
      </div>
      <div className="bg-secondary">
        <Profile />
        <Introduction />
        <Experience />
        <Skills />
        <Contact />
        <Cursor cursor={cursor} />
      </div>
    </div>
  );
}

export default App;
