import React from "react";
import Info from "./info";

function Experience() {
  return (
    <div className="mt-16 w-full lg:max-w-6xl mx-auto">
      <p className="text-center font-sans text-4xl font-thin text-title-black">
        Experience
      </p>
      <div className="text-center mt-8 pl-2">
        <div className="flex justify-start md:justify-center items-center">
          <i className="fas fa-briefcase w-16 h-16 text-white text-3xl bg-primary rounded-full flex justify-center items-center"></i>
        </div>
        <Info />
      </div>
    </div>
  );
}

export default Experience;
