import React from "react";
import awsSVG from "../../assets/svgs/aws.svg";
import bashSVG from "../../assets/svgs/bash.svg";
import dockerSVG from "../../assets/svgs/docker.svg";
import elasticsearchSVG from "../../assets/svgs/elasticsearch.svg";
import goSVG from "../../assets/svgs/go.svg";
import grafanaSVG from "../../assets/svgs/grafana.svg";
import javascriptSVG from "../../assets/svgs/javascript.svg";
import nodejsSVG from "../../assets/svgs/nodejs.svg";
import prometheusSVG from "../../assets/svgs/prometheus.svg";
import pythonSVG from "../../assets/svgs/python.svg";
import reactSVG from "../../assets/svgs/react.svg";
import tailwindcssSVG from "../../assets/svgs/tailwindCSS.svg";
import typescriptSVG from "../../assets/svgs/typescript.svg";
import terraformSVG from "../../assets/svgs/terraform.svg";
import Box from "./box";

function Skills() {
  const languages = [
    { src: goSVG, description: "Go" },
    { src: bashSVG, description: "Bash" },
    { src: pythonSVG, description: "Python" },
    { src: javascriptSVG, description: "JavaScript" },
    { src: typescriptSVG, description: "TypeScript" },
  ];

  const frameworks = [
    { src: awsSVG, description: "AWS" },
    { src: elasticsearchSVG, description: "Elasticsearch" },
    { src: dockerSVG, description: "Docker" },
    { src: terraformSVG, description: "Terraform" },
    { src: prometheusSVG, description: "Prometheus" },
    { src: grafanaSVG, description: "Grafana" },
    { src: reactSVG, description: "React JS" },
    { src: nodejsSVG, description: "Node JS" },
    { src: tailwindcssSVG, description: "Tailwind CSS" },
  ];

  const skillBoxes = [
    {
      heading: "I frequently speak in",
      skills: languages,
      animation: "viewportBelow",
    },
    {
      heading: "Probably busy building with",
      skills: frameworks,
      animation: "viewportBelow",
    },
  ];

  return (
    <div className="mt-20 md:mt-32 w-full lg:max-w-6xl mx-auto px-4">
      {skillBoxes.map(({ heading, skills, animation }, index) => (
        <Box
          heading={heading}
          animation={animation}
          array={skills}
          key={index}
        />
      ))}
    </div>
  );
}

export default Skills;
