import React, { useState } from "react";
import contactSVG from "../../assets/svgs/contact.svg";
import * as emailjs from "@emailjs/browser";
import ErrorBlock from "./ErrorBlock";

function Contact() {
  const [mail, setMail] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [error, setError] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);

  const validate = () => {
    let tempError = {
      name: "",
      email: "",
      message: "",
    };
    let valid = true;

    const EMAIL_REGEX =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    //checks
    if (mail.name === "") {
      valid = false;
      tempError.name = "You forgot to put your name.";
    }

    if (mail.email === "") {
      valid = false;
      tempError.email = "You forgot to put your email.";
    }

    if (mail.message === "") {
      valid = false;
      tempError.message = "You forgot to put your message.";
    }

    if (!EMAIL_REGEX.test(mail.email)) {
      valid = false;
      tempError.email = "Please enter a valid email.";
    }

    setError(tempError);
    return valid;
  };

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const submitForm = async () => {
    if (!validate()) return;
    setLoading(true);
    const templateParams = {
      full_name: mail.name,
      to_name: "rishabhmisha3321@gmail.com",
      subject: "Reach out from porfolio.",
      sender_email: mail.email,
      message: mail.message,
    };

    await emailjs.send(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      templateParams,
      process.env.REACT_APP_USER_ID
    );

    await sleep(1000);

    setLoading(false);
    setMail({
      name: "",
      email: "",
      message: "",
    });
  };

  return (
    <div
      className="text-gray-100 pb-10 pt-12 px-8 mt-32 bg-primary bg-opacity-80 bg-gradient-to-t from-purple-600"
      id="contact"
    >
      <div className="max-w-screen-xl px-8 grid gap-8 grid-cols-1 md:grid-cols-2 md:px-12 lg:px-16 xl:px-32 py-16 mx-auto bg-gray-100 text-gray-900 rounded-md shadow-lg">
        <div className="flex flex-col justify-between">
          <div>
            <h2 className="text-4xl lg:text-5xl font-bold leading-tight text-title-black">
              Lets talk about anything!
            </h2>
          </div>
          <div className="mt-8 text-center w-full">
            <img src={contactSVG} alt="Contact" />
          </div>
        </div>
        <div>
          <div>
            <span className="uppercase text-sm text-gray-600 font-semibold">
              Full Name
            </span>
            <input
              className="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-md focus:outline-none focus:shadow-outline"
              type="text"
              placeholder="Rishabh Mishra"
              value={mail.name}
              onChange={(e) => {
                setMail({ ...mail, name: e.target.value });
                setError({ ...error, name: "" });
              }}
            />
            <ErrorBlock error={error.name} />
          </div>
          <div>
            <span className="uppercase text-sm text-gray-600 font-bold">
              Email
            </span>
            <input
              className="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-md focus:outline-none focus:shadow-outline"
              type="text"
              placeholder="rishabhmishra3321@gmail.com"
              value={mail.email}
              onChange={(e) => {
                setMail({ ...mail, email: e.target.value });
                setError({ ...error, email: "" });
              }}
            />
            <ErrorBlock error={error.email} />
          </div>
          <div>
            <span className="uppercase text-sm text-gray-600 font-bold">
              Message
            </span>
            <textarea
              className="w-full h-32 bg-gray-300 text-gray-900 mt-2 p-3 rounded-md focus:outline-none focus:shadow-outline"
              placeholder="Hi there !!!"
              value={mail.message}
              onChange={(e) => {
                setMail({ ...mail, message: e.target.value });
                setError({ ...error, message: "" });
              }}
            ></textarea>
            <ErrorBlock error={error.message} />
          </div>
          <div className="mt-8 outline-none">
            <div
              className="uppercase text-sm font-bold tracking-wide bg-primary text-gray-100 p-3 rounded-md w-full focus:outline-none focus:shadow-outline text-center flex justify-center items-center transition-all ease-in-out duration-500 h-16 cursor-pointer"
              onClick={submitForm}
            >
              {loading ? <div className="loader" /> : "Send Message"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
