import React, { useEffect } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

function Box({ heading, array, animation }) {
  useEffect(() => {
    const obsOptions = {
      threshold: 0.1,
    };
    const inViewport = (entries) => {
      entries.forEach((entry) => {
        const animation = entry.target.dataset.animation;
        const classes = entry.target.classList;
        if (classes.value.search("animated") === -1 && entry.isIntersecting) {
          entry.target.classList.toggle(animation, entry.isIntersecting);
          entry.target.classList.toggle("invisible");
          entry.target.classList.add("animated");
        }
      });
    };

    const Obs = new IntersectionObserver(inViewport, obsOptions);

    const elements = document.querySelectorAll("[data-skills]");
    elements.forEach((EL) => {
      Obs.observe(EL);
    });
  }, []);

  return (
    <div className="mt-20">
      <p className="text-center font-sans text-2xl md:text-4xl font-thin text-title-black">
        {heading}
      </p>
      <div
        className="flex flex-wrap justify-center mt-5 gap-5 md:gap-8 w-full py-10 rounded-md shadow-2xl bg-white invisible"
        data-skills
        data-animation={animation}
      >
        {array.map((element, index) => {
          return (
            <div key={index}>
              <img
                src={element.src}
                alt={element.description}
                className="w-12 h-12 md:w-14 md:h-14 lg:h-20 lg:w-20 cursor-pointer"
                data-tooltip-id={element.description}
                data-tooltip-content={element.description}
                data-tooltip-delay-hide={200}
              />
              <ReactTooltip id={element.description} place="bottom" />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Box;
