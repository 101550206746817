import React, { useEffect } from "react";

const data = [
  {
    title: "SDE - 1",
    company: "Angel One",
    duration: "Jul 2022 - Present",
    description: [
      "Built and maintained an API Gateway (2000 TPS) for accessing internal microservices, customized for CORS, auth, and cookies.",
      "Designed and implemented a suggestive search using AWS Opensearch for the Angel One Tradebuddy platform, enhancing user experience.",
      "Designed and built the MTF Homepage using the BFF pattern, enabling product customization based on market hours.",
    ],
    animation: "viewportLeft",
  },
  {
    title: "Software Engineer Intern",
    company: "Paytm",
    duration: "Jan 2022 - Jun 2022",
    description: [
      "Designed and implemented the entire observability stack (Prometheus, Grafana, Elastic APM) for the Paytm Personal Loan Team.",
      "Automated alert generation for internal workflows and new client onboarding.",
      "Developed pipelines for pushing checkpoints to Prometheus for building comprehensive Grafana/Kibana dashboards for business insights.",
    ],
    animation: "viewportRight",
  },
];

function Info() {
  useEffect(() => {
    const obsOptions = {
      threshold: 0.1,
    };
    const inViewport = (entries, observer) => {
      entries.forEach((entry) => {
        const animation = entry.target.dataset.animation;
        const classes = entry.target.classList;
        if (classes.value.search("animated") === -1 && entry.isIntersecting) {
          entry.target.classList.toggle(animation, entry.isIntersecting);
          entry.target.classList.add("animated");
          entry.target.classList.toggle("invisible");
        }
      });
    };

    const Obs = new IntersectionObserver(inViewport, obsOptions);

    const elements = document.querySelectorAll("[data-info]");
    elements.forEach((EL) => {
      Obs.observe(EL);
    });
  }, []);

  return (
    <div className="flex flex-wrap w-full pl-7.4 md:px-0">
      {data.map((item, index) => {
        const isLeft = index % 2;
        return (
          <div className="w-full flex flex-wrap" key={index}>
            <div
              className={`w-full md:w-1/2 border-l-4 md:border-l-0 ${
                isLeft ? "md:border-l-2" : "md:border-r-2"
              } border-primary order-2 ${isLeft ? "md:order-2" : "md:order-1"}`}
            >
              <div
                className="mt-10 w-full invisible"
                data-info
                data-animation={item.animation}
              >
                <div className={`p-6 pb-1 ${isLeft ? "lg:pl-10" : "lg:pr-10"}`}>
                  <div className="bg-white p-6 text-left relative shadow-2xl rounded-sm">
                    <p className="font-sans text-title-black text-lg">
                      {item.company}
                    </p>
                    <div className="bg-secondary p-2 font-sans text-sm font-light text-title-black my-2 inline-block rounded-sm shadow-lg">
                      {item.title}
                    </div>
                    <div className="font-light text-subtitle-grey font-sans text-base">
                      <ul className="list-disc list-outside px-4">
                        {item.description.map((desc) => (
                          <li className="my-1" key={desc}>
                            {desc}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div
                      className={`hidden md:block absolute text-white text-3xl rounded-full top-4 ${
                        isLeft ? "md:-left-4" : "md:-right-4"
                      } ${isLeft ? "lg:-left-5" : "lg:-right-5"}`}
                    >
                      {isLeft ? <div>&#9668; </div> : <div>&#9658; </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`w-full md:w-1/2 border-l-4 md:border-l-0 ${
                isLeft ? "md:border-r-2" : "md:border-l-2"
              } border-primary relative  order-1 ${
                isLeft ? "md:order-1" : "md:order-2"
              } `}
            >
              <div className="mt-10"></div>
              <div
                className={`absolute -left-4.4 ${
                  isLeft && "md:left-auto"
                } top-10 md:top-20 inline-block ${
                  isLeft ? "md:-right-4.4" : "md:-left-4.4"
                }`}
              >
                <i className="fas fa-check-circle text-primary text-2xl rounded-full w-8 h-8 bg-secondary"></i>
              </div>
              <div
                className={`absolute left-10 ${
                  isLeft && "md:left-auto"
                } top-10 md:top-20 bg-primary text-white p-1 text-sm inline-block rounded-sm font-sans ${
                  isLeft ? "md:right-6" : "md:left-6"
                } `}
              >
                {item.duration}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Info;
